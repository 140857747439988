import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';
import { Home } from '../Home';
import { useMsal } from '@azure/msal-react';
import { loginRequest, b2cPolicies } from '../authConfig';

export const PageLayout = (props) => {

    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }
    if(!activeAccount){
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }

    return (
        <>
            
            <AuthenticatedTemplate>
                <Home />
                
            </AuthenticatedTemplate>
        </>
    );
};
