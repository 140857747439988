import logo from './logo.svg';
import './App.css';
import { Button, Typography, TextField, Divider, InputAdornment, Tab, Tabs, Stack, Paper, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, RadioGroup, FormControl, FormLabel, Radio, InputLabel, Select, MenuItem, Card, CardActionArea, Slide, Snackbar, IconButton, Rating, Backdrop, CircularProgress, Avatar, Icon, Popover, Tooltip, Badge } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState, useEffect, useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from "@mui/system";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import dayjs from 'dayjs';
import { DigitalClock, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Map } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from "@mui/icons-material/Info";
import PublicIcon from '@mui/icons-material/Public';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from "react-copy-to-clipboard";
import { useMsal } from '@azure/msal-react';
import { TERMS_TEXT } from './Terms';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function StyledTab(props)
{
  return <Tab style={{fontSize: "20px"}} {...props} />
}

export function Home() {

  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }

  //console.log(activeAccount.idTokenClaims);

  const [tab, setTab] = useState(0);

  const [bookingsTab, setBookingsTab] = useState(0);

  const [profile, setProfile] = useState();

  const [addAvailabilityDialogOpen, setAddAvailabilityDialogOpen] = useState(false);

  const [addOfferDialogOpen, setAddOfferDialogOpen] = useState(false);

  const [bookingDetailsDialogOpen, setBookingDetailsDialogOpen] = useState(false);

  const [addDealDialogOpen, setAddDealDialogOpen] = useState(false);

  const [bookingCompletedDialogOpen, setBookingCompletedDialogOpen] = useState(false);

  const [cancelBookingDialogOpen, setCancelBookingDialogOpen] = useState(false);

  const [onTheWayDialogOpen, setOnTheWayDialogOpen] = useState(false);

  const [newAvailability, setNewAvailability] = useState({"daysOfWeek": [], "fromTime": "0900", "toTime": "1700"});

  const [newOffer, setNewOffer] = useState({"massageType": "swedish", "duration": "60", "cost": "120.00", "description": "A relaxing massage!"});

  const [newDeal, setNewDeal] = useState({ "offer": 0, "discount": "20", "description": "Merry Christmas!" })

  const [selectedBooking, setSelectedBooking] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [paymentCode, setPaymentCode] = useState("");

  const [loadingBackropOpen, setLoadingBackdropOpen] = useState(false);

  const [saveAfterProfileChange, setSaveAfterProfileChange] = useState(false);

  const [profileBuffer, setProfileBuffer] = useState({});

  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] = useState(false);

  const [newTabBuffer, setNewTabBuffer] = useState();

  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(false);

  const [isProfileValidated, setIsProfileValidated] = useState(false);

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const [popoverText, setPopoverText] = useState("");

  const [tabValidities, setTabValidities] = useState({"profile": false, "services": false, "availabilities": false});

  const [disableDialogActionButton, setDisableDialogActionButton] = useState(false);

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    window.onbeforeunload = function(){
      return !deepEqual(profileBuffer, profile) ? true : null;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [profileBuffer, profile])

  const isFirstRender = useRef(true);

  const [didValidateProfileFirstTime, setDidValidateProfileFirstTime] = useState(false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
    if(saveAfterProfileChange)
    {
      saveProfile(); // do something after state has updated
      setSaveAfterProfileChange(false);
    }
    if(profile && !didValidateProfileFirstTime)
    {
      validateProfile();
      setDidValidateProfileFirstTime(true);
    }
  }, [profile])

  const validateProfile = () => {

    if(!profile)
    {
      return;
    }

    let newTabValidities = {"profile": false, "services": false, "availabilities": false}

    if(!(!profile['profilePicture'] || !profile["name"] || !profile["address"] || !profile["postalCode"] || !profile["displayName"] || !profile["pitch"] || !profile["description"] || !profile["interacEmail"] || !profile["email"] || !profile["city"] || !profile["province"] || profile["displayImages"].length == 0))
    {
      newTabValidities["profile"] = true;
    }
    if(profile["offers"].length > 0)
    {
      newTabValidities["services"] = true;
    }
    if(profile["availabilities"].length > 0)
    {
      newTabValidities["availabilities"] = true;
    }

    if(newTabValidities["profile"] && newTabValidities["services"] && newTabValidities["availabilities"])
    {
      setIsProfileValidated(true);
    }
    else
    {
      setIsProfileValidated(false);
    }

    setTabValidities(newTabValidities);
  }

  const loadProfile = () => {

    showLoadingBackrop();

    // Define the URL of your Azure Function endpoint
    const functionUrl = 'https://func-bamboo-app-service.azurewebsites.net/api/GetProfile?code=lfYp50osp53vmzq2fdxO3R9SVsrkIDYaurrJwnpSf6IhAzFuhTi97A==';

    // Document ID you want to fetch
    const documentId = activeAccount.homeAccountId;

    // Construct the full URL with the document ID as a query parameter
    const urlWithParams = `${functionUrl}&id=${documentId}&createIfNotExists=1`;

    // Use the fetch API to send the request
    fetch(urlWithParams, {
      method: 'GET', // Specify the request method
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch document');
        }
        return response.json(); // Parse the response body as JSON
      })
      .then(data => {
        // The data object now contains the Cosmos DB document
        console.log('Document fetched successfully:', data);
        if(data["email"].length == 0)
        {
          handleWelcomeDialogOpen();
          data["email"] = activeAccount.idTokenClaims["emails"][0];
          data["displayName"] = activeAccount.idTokenClaims["name"];
          data["name"] = activeAccount.idTokenClaims["extension_FullName"];
          //data["postalCode"] = activeAccount.idTokenClaims["postalCode"];
          //data["city"] = activeAccount.idTokenClaims["city"];
          //data["address"] = activeAccount.idTokenClaims["extension_Address"];
        }
        setProfile(data);
        setProfileBuffer(data);
        hideLoadingBackrop();
        // You can now use the data object as needed in your application
        // For example, store it in a variable or state if using a framework like React
      })
      .catch(error => {
        console.error('Error:', error);
        hideLoadingBackrop();
      });
  }

  const saveProfile = () => {

    showLoadingBackrop();

    setProfileBuffer(JSON.parse(JSON.stringify(profile)));

    let functionURL = "https://func-bamboo-app-service.azurewebsites.net/api/SaveProfile?code=aOeIHNYcpSiNk--oFKFnBrfL57dPb6GGngIyPGkTXnQeAzFuXtSh5g==";

    // Use the fetch API to send the data as a POST request
    fetch(functionURL, {
      method: 'POST', // Use POST method
      headers: {
        'Content-Type': 'application/json', // Indicate JSON payload
      },
      body: JSON.stringify(profile), // Convert data to JSON string
    })
      .then(response => response.text()) // Convert the response to text
      .then(text => {console.log(text); hideLoadingBackrop(); validateProfile();}) // Log the response text to the console
      .catch(error => {console.error('Error:', error); hideLoadingBackrop();}); // Log any errors
  }

  const showLoadingBackrop = () => {
    setLoadingBackdropOpen(true);
  }

  const hideLoadingBackrop = () => {
    setLoadingBackdropOpen(false);
  }

  const handlePopoverOpen = (event, message) => {
    setPopoverAnchor(event.currentTarget);
    setPopoverText(message);
  }

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  }

  const uploadImages = (images, callBack) => {
    //debugger;

    showLoadingBackrop();
    // Create a FormData object
    let formData = new FormData();

    // Append files to the FormData object
    // If you're uploading multiple files under the same form name, you can do this in a loop
    images.forEach((file, index) => {
      formData.append(`file${index}`, file, file.name);
    });

    // Define the URL to which you're sending the data
    const uploadURL = 'https://func-bamboo-app-service.azurewebsites.net/api/UploadProfileMediaToBlob?code=1EnflTJNHnx8rfJoOC-8X7lJTGXUmoVICqZJyAnRlRBFAzFuwMiBVA==';

    // Use fetch API to perform the upload
    fetch(uploadURL, {
      method: 'POST', // or 'PUT'
      body: formData, // The FormData object containing the files
      // Don't set content type to multipart/form-data here, as the browser will do it for you,
      // including setting the boundary parameter correctly.
    })
      .then(response => response.text()) // Assuming the server responds with text
      .then(result => {
        result = result.replace(/'/g, '"');
        callBack(JSON.parse(result));
        hideLoadingBackrop();
      })
      .catch(error => {
        hideLoadingBackrop();
        console.error('Error:', error);
      });
  }

  const handleSignOut = () => {
    instance.logoutRedirect();
  }

  const handlePaymentCodeChange = (e) => {
    setPaymentCode(e.target.value);
  }

  const showSnackbar = (message) => {
    if(snackbarOpen)
    {
      setSnackbarOpen(false);
      setTimeout(() => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }, 350);
      return;
    }
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }

  const hideSnackbar = () => {
    setSnackbarOpen(false);
  }

  const handleNewAvailabilityDayChange = (e, dayId) => {
    let currentDaysSelected = newAvailability.daysOfWeek;

    if (e.target.checked) {
      currentDaysSelected.push(dayId);
      currentDaysSelected.sort(function (a, b) { return a - b });
    }
    else {
      currentDaysSelected = currentDaysSelected.filter(e => e !== dayId);
    }
    setNewAvailability({ ...newAvailability, "daysOfWeek": currentDaysSelected });

  }

  const resetNewAvailabilityValue = () => {
    setNewAvailability({ "daysOfWeek": [], "fromTime": "0900", "toTime": "1700" });
  }

  const resetNewOffer = () => {
    setNewOffer({massageType: "swedish", duration: "60", cost: "120.00", description: ""});
  }

  const resetNewDeal = () => {
    setNewDeal({offer: 0, discount: "20", description: ""});
  }

  const handleNewAvailabilityFromTimeChange = (newValue) => {
    setNewAvailability({...newAvailability, "fromTime": dayJSObjectToMilitaryTime(newValue)});
  }

  const handleNewAvailabilityToTimeChange = (newValue) => {
    setNewAvailability({ ...newAvailability, "toTime": dayJSObjectToMilitaryTime(newValue)});
  }

  const handleAddOfferMassageTypeChange = (e) => {
    setNewOffer({ ...newOffer, "massageType": e.target.value })
  }

  const handleAddOfferMassageDurationChange = (e) => {
    setNewOffer({ ...newOffer, "duration": e.target.value })
  }

  const handleAddOfferRateChange = (e) => {
    setNewOffer({ ...newOffer, "cost": e.target.value })
  }

  const handleAddOfferDescriptionChange = (e) => {
    setNewOffer({...newOffer, "description": e.target.value})
  }

  const handleAddDealChange = (e, variableName) => {
    let dealMut = JSON.parse(JSON.stringify(newDeal));
    dealMut[variableName] = e.target.value;
    setNewDeal(dealMut);
  }

  const setProfileVariable = (variableName, newValue) => {
    let profileMut = JSON.parse(JSON.stringify(profile));
    profileMut[variableName] = newValue;
    setProfile(profileMut);
  }

  const handleAddNewAvailability = () => {
    setAddAvailabilityDialogOpen(false);
    setProfileVariable("availabilities", [...profile.availabilities, newAvailability])
    showSnackbar("Availability added");
  }

  const handleAddNewOffer = () => {
    setAddOfferDialogOpen(false);
    setProfileVariable("offers", [...profile.offers, newOffer])
    showSnackbar("Service added");
  }

  const handleAddNewDeal = () => {
    setAddDealDialogOpen(false);
    setProfileVariable("deals", [...profile.deals, newDeal])
    showSnackbar("Deal added");
  }

  const handleCancelSelectedBooking = () => {

    const urlBase = "https://func-bamboo-app-service.azurewebsites.net/api/CancelBooking?code=ifNmJbszd4VT4QeGh1AkYdY0fMvhRfZQ6YstG5jFo7U5AzFuaD3Uig==";

    const url = `${urlBase}&id=${profile['id']}&epoch=${selectedBooking['epoch']}`;

    setDisableDialogActionButton(true);

    fetch(url).then(response => {
      setDisableDialogActionButton(false);
      if(response.status == 200)
      {
        setSaveAfterProfileChange(true);
        setCancelBookingDialogOpen(false);
        for(let i = 0; i < profile.bookings.length; i++)
        {
          if(deepEqual(profile.bookings[i], selectedBooking))
          {
            let bookingsMut = JSON.parse(JSON.stringify(profile.bookings));
            bookingsMut[i].canceled = true;
            setProfileVariable("bookings", bookingsMut);
            showSnackbar("Booking canceled");
          }
        }
      }
      else
      {
        alert("Something went wrong.");
      }
    })

  }

  const handleCompletedBooking = () => {
    
    const urlBase = "https://func-bamboo-app-service.azurewebsites.net/api/SubmitBookingCompletion?code=rA244Xvr6ilRo-mW8PUcHDujCNKa6cRuaYf7lVPC9UsvAzFuph-DLg==";

    const url = `${urlBase}&id=${profile['id']}&paymentCode=${paymentCode}&epoch=${selectedBooking['epoch']}`;

    setDisableDialogActionButton(true);

    fetch(url).then(response => {
      setDisableDialogActionButton(false);
      if(response.status == 200)
      {
        setSaveAfterProfileChange(true);
        setBookingCompletedDialogOpen(false);
        for (let i = 0; i < profile.bookings.length; i++) {
          if (deepEqual(profile.bookings[i], selectedBooking)) {
            let bookingsMut = JSON.parse(JSON.stringify(profile.bookings));
            bookingsMut[i].completed = true;
            setProfileVariable("bookings", bookingsMut);
            showSnackbar("Booking completed");
          }
        }
      }
      else
      {
        alert("Invalid payment code.");
      }
    })

  }

  const handleOnTheWay = () => {

    const urlBase = "https://func-bamboo-app-service.azurewebsites.net/api/SendOnTheWayNotification?code=1Sz_JXf8gd6bBqQVvKQcs0wpS5Thf2-SUooVW2dRkRbkAzFu1vdNdg==";

    const url = `${urlBase}&id=${profile['id']}&epoch=${selectedBooking['epoch']}`;

    setDisableDialogActionButton(true);

    fetch(url).then(response => {
      setDisableDialogActionButton(false);
      if(response.status == 200)
      {
        setSaveAfterProfileChange(true);
        setOnTheWayDialogOpen(false);
        for (let i = 0; i < profile.bookings.length; i++) {
          if (deepEqual(profile.bookings[i], selectedBooking)) {
            let bookingsMut = JSON.parse(JSON.stringify(profile.bookings));
            bookingsMut[i].onTheWay = true;
            setProfileVariable("bookings", bookingsMut);
            showSnackbar("Notified customer");
          }
        }
      }
      else
      {
        alert("Something went wrong.");
      }
    })

  }

  const handleAvailabilityDialogClose = () => {
    setAddAvailabilityDialogOpen(false);
  }

  const handleAvailabilityDialogOpen = () => {
    setAddAvailabilityDialogOpen(true);
    resetNewAvailabilityValue();
  }

  const handleAddOfferDialogClose = () => {
    setAddOfferDialogOpen(false);
  }

  const handleAddOfferDialogOpen = () => {
    resetNewOffer();
    setAddOfferDialogOpen(true);
  }

  const handleBookingDetailsDialogClose = () => {
    setBookingDetailsDialogOpen(false);
  }

  const handleBookingDetailsDialogOpen = (index) => {
    setSelectedBooking(profile.bookings[index]);
    setBookingDetailsDialogOpen(true);
  }

  const handleAddDealDialogClose = () => {
    setAddDealDialogOpen(false);
  }

  const handleAddDealDialogOpen = () => {
    resetNewDeal();
    setAddDealDialogOpen(true);
  }

  const handleBookingCompletedDialogOpen = (index) => {
    setPaymentCode("");
    setBookingCompletedDialogOpen(true);
    setSelectedBooking(profile.bookings[index]);
  }

  const handleBookingCompletedDialogClose = () => {
    setBookingCompletedDialogOpen(false);
  }

  const handleCancelBookingConfirmationDialogOpen = (index) => {
    setCancelBookingDialogOpen(true)
    setSelectedBooking(profile.bookings[index]);
  }

  const handleCancelBookingConfirmationDialogClose = () => {
    setCancelBookingDialogOpen(false);
  }

  const handleOnTheWayDialogOpen = (index) => {
    setOnTheWayDialogOpen(true);
    setSelectedBooking(profile.bookings[index]);
  }

  const handleOnTheWayDialogClose = () => {
    setOnTheWayDialogOpen(false);
  }

  const handleWelcomeDialogOpen = () => {
    setWelcomeDialogOpen(true);
  }

  const handleWelcomeDialogClose = () => {
    if(!profile['termsAccepted'])
    {
      let profileMut = JSON.parse(JSON.stringify(profile));
      profileMut['termsAccepted'] = TERMS_TEXT;
      profileMut['termsAcceptAt'] = Math.floor(Date.now() / 1000).toString();
      setProfile(profileMut);
    }

    setWelcomeDialogOpen(false);
  }

  const handleDiscardChanges = () => {
    setProfile(JSON.parse(JSON.stringify(profileBuffer)));
    setTab(newTabBuffer);
    setDiscardChangesDialogOpen(false);
  }

  const handleCancelDiscardChanges = () => {
    setDiscardChangesDialogOpen(false);
  }

  const handleSaveFromDiscardChangesDialog = () => {
    saveProfile();
    setDiscardChangesDialogOpen(false);
    setTab(newTabBuffer);
  }

  function handleProfilePictureChange(e) {
    if(e.target.files[0])
    {
      uploadImages([e.target.files[0]], (urls) => {
        setProfileVariable("profilePicture", urls[0]);
      });
    }
  }

  const handleDisplayImagesChange = (e) => {
    console.log(e.target.files);
    if(e.target.files)
    {
      uploadImages(Array.from(e.target.files), (urls) => {
        setProfileVariable("displayImages", [...profile.displayImages, ...urls]);

      })
    }
  }

  const handleNameChange = (e) => {
    setProfileVariable("name", e.target.value);
  }

  const handleDisplayNameChange = (e) => {
    setProfileVariable("displayName", e.target.value);
  }

  const handleDescriptionChange = (e) => {
    setProfileVariable("description", e.target.value);
  }

  const handleCityChange = (e) => {
    setProfileVariable("city", e.target.value);
  }

  const handleProvinceChange = (e) => {
    setProfileVariable("province", e.target.value);
  }

  const handlePitchChange = (e) => {
    setProfileVariable("pitch", e.target.value);
  }

  const handleAddressChange = (e) => {
    setProfileVariable("address", e.target.value);
  }

  const handleRMTNumberChange = (e) => {
    setProfileVariable("rmtNumber", e.target.value);
  }

  const handlePostalCodeChange = (e) => {
    setProfileVariable("postalCode", e.target.value);
  }

  const handleInteracEmailChange = (e) => {
    setProfileVariable("interacEmail", e.target.value);
  }

  const handleTabChange = (e, newValue) => {
    if(!deepEqual(profile, profileBuffer))
    {
      setDiscardChangesDialogOpen(true);
      setNewTabBuffer(newValue);
      return;
    }
    
    setProfile(JSON.parse(JSON.stringify(profileBuffer)));
    setTab(newValue);
  }

  const handleBookingsTabChange = (e, newValue) => {
    setBookingsTab(newValue);
  }

  const weekdayIdToName = (id) => {
    const weekdays = ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"];

    return weekdays[id];
  }

  const convertMilitaryTimeToTwelveHour = (militaryTime) => {
    // Validate input
    if (!/^\d{4}$/.test(militaryTime)) {
      throw new Error('Invalid time format. Please use a 4-digit military time format.');
    }

    // Extract hours and minutes from the input
    const hours = parseInt(militaryTime.substring(0, 2), 10);
    const minutes = militaryTime.substring(2);

    // Convert hours from 24-hour to 12-hour format
    const twelveHour = hours % 12 || 12;
    // Determine AM or PM
    const amPm = hours < 12 ? "AM" : "PM";

    // Format the time in 12-hour format
    return `${twelveHour}:${minutes} ${amPm}`;
  }

  const militaryTimeToDayJSObject = (militaryTime) => {
    return dayjs(`2024-03-01T${militaryTime.slice(0, 2)}:${militaryTime.slice(2, 4)}`);
  }

  const dayJSObjectToMilitaryTime = (dayjsObject) => {
    return (dayjsObject.get("hour").toString().length == 1 ? "0" : "") + dayjsObject.get("hour").toString() + (dayjsObject.get("minute").toString().length == 1 ? "0" : "") + dayjsObject.get("minute").toString();
  }

  const validateAvailability = (availability) => {
    if (availability.daysOfWeek.length == 0) {
      return [false, "Please select a day."];
    }
    if (parseInt(availability.fromTime) >= parseInt(availability.toTime)) {
      return [false, "Invalid time range."];
    }

    return [true];

  }

  const hasEpochTimePassed = (epochSeconds) => {
    // Get the current time in seconds
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    // Compare the current time with the epoch time
    return currentTimeInSeconds > epochSeconds;
  }

  const convertUnixTimestampToCustomFormat = (unixTimestamp) => {
    // Convert the Unix timestamp from seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Build the custom date string
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();

    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    const minuteFormatted = minute < 10 ? '0' + minute : minute;

    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth} at ${hour}:${minuteFormatted} ${ampm}`;

    return formattedDate;
  }

  const extractNumberFromString = (amount) => {
    // Use a regular expression to match the numeric part of the string
    const match = amount.match(/[\d\.]+/);
    // If a match is found, return it as a string, otherwise return an empty string
    return match ? match[0] : '';
  }

  const deepEqual = (object1, object2) => {
    console.log(object1, object2);
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        areObjects && !deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }

    return true;
  }

  function isObject(object) {
    return object != null && typeof object === 'object';
  }

  const stringifyAvailability = (availability) => {

    if (validateAvailability(availability)[0] == false) {
      return validateAvailability(availability)[1];
    }

    //Example: '0,2 1230-1530' should map to 'Monday and Wednesday from 12:30pm to 3:30pm'

    let result = "";

    let days = availability.daysOfWeek;

    for (let dayId of days) {
      result += weekdayIdToName(dayId) + (days.indexOf(dayId) === days.length - 2 ? " and " : (days.indexOf(dayId) === days.length - 1 ? "" : ", "));
    }

    result += ` from ${convertMilitaryTimeToTwelveHour(availability.fromTime)} to ${convertMilitaryTimeToTwelveHour(availability.toTime)}`;

    return result;

  }

  const formatMassageType = (str) => {
    //debugger;
    // Replace all '-' with spaces
    let formattedStr = str.replace(/-/g, ' ');

    // Capitalize the first letter
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

    return formattedStr;
  }

  const sortDescendingByEpoch = (arr) => {
    return arr.sort((a, b) => {
      // Handle cases where "epoch" might be misspelled or missing by using a default value.
      // If "epoch" is missing or misspelled in an object, treat its value as 0 for sorting purposes.
      const epochA = a.epoch !== undefined ? a.epoch : 0;
      const epochB = b.epoch !== undefined ? b.epoch : 0;

      // Return negative, zero, or positive value based on comparison for descending order.
      return epochB - epochA;
    });
  }

  const stringifyOffer = (offer) => {
    return formatMassageType(offer.massageType) + " | " + offer.duration + " minutes | " + "$" + offer.cost;
  }

  const stringifyBooking = (booking) => {
    return `${convertUnixTimestampToCustomFormat(booking.epoch)} with ${booking.customerName}`;
  }

  const stringifyDeal = (deal) => {
    debugger;
    return `${formatMassageType(profile.offers[deal.offer].massageType)} | ${profile.offers[deal.offer].duration} minutes - ${deal.discount}% off - ${deal.description}`;
  }

  const getBookingIndex = (booking) => {
    for(let i = 0; i < profile.bookings.length; i++)
    {
      if(deepEqual(profile.bookings[i], booking))
      {
        return i;
      }
    }
    return -1;
  }

  if(!profile)
  {
    return <Backdrop
      open={loadingBackropOpen}
    >
      <CircularProgress style={{ color: "white" }} />
    </Backdrop>
  }

  const customerPortalBaseUrl = "https://bamboomassage.ca";

  return (
    <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", backgroundImage: "url('/WomanMassageBlurred.png')", backgroundSize: "cover" }}>

      <Grid container spacing={2} style={{ minWidth: "370px", width: "90%", maxWidth: "900px", minHeight: "80%", justifyContent: "center", backgroundColor: "#ffffff", padding: "20px", borderRadius: "20px", boxShadow: "0px 10px 8px #888888" }}>
        <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src="/Bamboo-Logo-Dark.png" width="300px" height="80px" />
          <IconButton onClick={(event) => handlePopoverOpen(event, "Contact support at: support@bamboomassage.ca")}>
            <HelpIcon fontSize='large' />
          </IconButton>
        </Grid>
        <Grid xs={12} style={{display: "inline-flex", alignItems: "center"}}>
          <Typography variant='h6' sx={{marginRight: "10px"}}>
            {profile.displayName}
          </Typography>
          <Tooltip title={isProfileValidated ? "Your profile is live!" : "Your account is not live. Please fill out all required information in the tabs marked with an exclamation point."}>
            <IconButton>
              {isProfileValidated ? <PublicIcon sx={{color: "#009eff"}} fontSize='large' /> : <ErrorIcon sx={{color: "#ff0000"}} fontSize='large' />}
            </IconButton>
          </Tooltip>
          {isProfileValidated && <CopyToClipboard text={customerPortalBaseUrl + "/" + profile['id']}>
              <Button variant='outlined' style={{marginLeft: "20px"}} startIcon={<LinkIcon fontSize="large" style={{ color: "#417ad2" }} />} onClick={(event) => handlePopoverOpen(event, "URL copied to clipboard!")} sx={{marginLeft: "10px"}}>
                  Share Profile
              </Button>
          </CopyToClipboard>}
        </Grid>
        <Grid xs={11} style={{display: "flex" }}>
          Rating:
          <Rating readOnly value={profile.rating} precision={0.5} style={{marginLeft: "5px", marginRight: "5px"}} />
          ({profile.ratingCount})
        </Grid>
        <Grid xs={1}>
          
        </Grid>
        <Grid xs={12}>
          <Tabs value={tab} onChange={handleTabChange} variant='scrollable' allowScrollButtonsMobile={true} scrollButtons="auto">
            <StyledTab label={<span style={{display: "inline"}}>1. Profile {!tabValidities["profile"] && <ErrorIcon sx={{color: "#ff0000"}} />}</span>} />
            <StyledTab label={<span style={{display: "inline"}}>2. Services {!tabValidities["services"] && <ErrorIcon sx={{color: "#ff0000"}} />}</span>} />
            <StyledTab label={<span style={{display: "inline"}}>3. Availability {!tabValidities["availabilities"] && <ErrorIcon sx={{color: "#ff0000"}} />}</span>} />
            <StyledTab label="Bookings" />
            {/* <StyledTab label="Deals" /> */}
          </Tabs>
        </Grid>
        <div style={{ height: "50vh", width: "100%", overflowY: "scroll" }}>
          {tab === 0 && <div>
            <Grid xs={12} sx={{display: "inline-flex", alignItems: "center"}}>
              <Typography variant='h3'>
                Profile
              </Typography>
              <IconButton onClick={handleWelcomeDialogOpen} sx={{marginLeft: "10px"}}>
                <InfoIcon />
              </IconButton>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12} style={{ display: "flex", justifyContent: "left" }}>
              <Avatar src={profile.profilePicture} />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                style={{ marginLeft: "10px" }}
              >
                Upload profile picture
                <VisuallyHiddenInput type="file" accept="image/*" onChange={handleProfilePictureChange} />
              </Button>
            </Grid>
            <Grid xs={12}>
              <TextField label="Legal Name" variant="outlined" fullWidth value={profile.name} onChange={handleNameChange} disabled />
            </Grid>
            <Grid xs={12}>
              <TextField label="Display Name" variant="outlined" fullWidth value={profile.displayName} onChange={handleDisplayNameChange} disabled />
            </Grid>
            {/*<Grid xs={12}>
              <TextField label="RMT Number" variant="outlined" fullWidth value={profile.rmtNumber} onChange={handleRMTNumberChange} />
            </Grid>*/}
            <Grid xs={12}>
              <TextField label="Tagline" placeholder='Relax with my professional massages!' variant="outlined" fullWidth value={profile.pitch} onChange={handlePitchChange} />
            </Grid>
            <Grid xs={12}>
              <TextField multiline label="Description" placeholder={`Hey! I'm ${profile.displayName}, and I've been giving massages for 15 years.`} variant="outlined" fullWidth value={profile.description} onChange={handleDescriptionChange} />
            </Grid>
            <Grid xs={12}>
              <TextField label="Interac Email for Receiving Payments" variant="outlined" fullWidth value={profile.interacEmail} onChange={handleInteracEmailChange} />
            </Grid>
            <Grid xs={12}>
              <TextField label="Email" variant="outlined" fullWidth value={profile.email} disabled />
            </Grid>
            <Grid xs={12}>
              <TextField label="Address" variant="outlined" fullWidth value={profile.address} onChange={handleAddressChange} />
            </Grid>
            <Grid xs={12}>
              <TextField multiline label="City" variant="outlined" fullWidth value={profile.city} onChange={handleCityChange} />
            </Grid>
            <Grid xs={12}>
              <TextField multiline label="Province" variant="outlined" fullWidth value={profile.province} onChange={handleProvinceChange} />
            </Grid>
            <Grid xs={12}>
              <TextField label="Postal Code" variant="outlined" fullWidth value={profile.postalCode} onChange={handlePostalCodeChange} />
            </Grid>
            <Grid xs={12} style={{ display: "flex", justifyContent: "left" }}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                style={{ marginLeft: "10px" }}
              >
                Upload display images
                <VisuallyHiddenInput type="file" accept="image/*" multiple onChange={handleDisplayImagesChange} />
              </Button>
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "left" }}>
              {profile.displayImages.map(imageURL => <Grid xs={2} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <img src={imageURL} style={{ width: "100px" }} />
                <IconButton onClick={() => setProfileVariable("displayImages", profile.displayImages.filter(image => image != imageURL))}>
                  <ClearIcon style={{color: "red"}} />
                </IconButton>
                </Grid>)}


            </Grid>
          </div>}
          {tab === 2 && <div>
            <Grid xs={12}>
              <Typography variant='h3'>
                Availability
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Stack spacing={1}>
              {profile.availabilities.map(availability => {
                return (
                  <Grid xs={12}>
                    <Paper>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h6'>
                          {stringifyAvailability(availability)}
                        </Typography>
                        <RemoveCircleIcon style={{ color: "red" }} onClick={() => showSnackbar("Availability removed") | setProfileVariable("availabilities", profile.availabilities.filter(i => i !== availability))} />
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
            </Stack>

            <Grid xs={12}>
              <Button onClick={handleAvailabilityDialogOpen}>Add New Availability</Button>
            </Grid>

            <Dialog open={addAvailabilityDialogOpen} onClose={handleAvailabilityDialogClose} maxWidth="md" fullWidth>
              <DialogTitle>
                Add new availability
              </DialogTitle>
              <DialogContent>
                <FormGroup>
                  <Typography variant='h4'>
                    {stringifyAvailability(newAvailability)}
                  </Typography>
                  <br />
                  <br />
                  <Typography variant='h5'>
                    Days
                  </Typography>

                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(0)} onChange={(e) => handleNewAvailabilityDayChange(e, 0)} />} label="Mondays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(1)} onChange={(e) => handleNewAvailabilityDayChange(e, 1)} />} label="Tuesdays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(2)} onChange={(e) => handleNewAvailabilityDayChange(e, 2)} />} label="Wednesdays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(3)} onChange={(e) => handleNewAvailabilityDayChange(e, 3)} />} label="Thursdays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(4)} onChange={(e) => handleNewAvailabilityDayChange(e, 4)} />} label="Fridays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(5)} onChange={(e) => handleNewAvailabilityDayChange(e, 5)} />} label="Saturdays" />
                  <FormControlLabel control={<Checkbox checked={newAvailability.daysOfWeek.includes(6)} onChange={(e) => handleNewAvailabilityDayChange(e, 6)} />} label="Sundays" />
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant='h5'>Time</Typography>
                    <br />
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant='h6'>From</Typography>
                        <DigitalClock value={militaryTimeToDayJSObject(newAvailability.fromTime)} onChange={handleNewAvailabilityFromTimeChange} />
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant='h5'>To</Typography>
                        <DigitalClock minTime={militaryTimeToDayJSObject(newAvailability.fromTime).add(30, "minute")} value={militaryTimeToDayJSObject(newAvailability.toTime)} onChange={handleNewAvailabilityToTimeChange} />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAvailabilityDialogClose}>Cancel</Button>
                <Button onClick={handleAddNewAvailability} disabled={!validateAvailability(newAvailability)[0]}>Add</Button>
              </DialogActions>
            </Dialog>
          </div>}
          {tab === 3 && <div>
            
            <Grid xs={12} style={{display: "flex"}}>
              <Typography variant='h3'>
                Bookings
              </Typography>
              <Tooltip title='Refresh to update your bookings.'>
                <IconButton size='large' onClick={loadProfile}>
                  <RefreshIcon style={{color: "green"}} fontSize='large' />
                </IconButton>
              </Tooltip>
            </Grid>
            
            
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Tabs value={bookingsTab} onChange={handleBookingsTabChange} variant='scrollable' allowScrollButtonsMobile={true} scrollButtons="auto">
              <Tab label="Upcoming" />
              <Tab label="In Progress" />
              <Tab label="Completed" />
              <Tab label="Cancelled" />
            </Tabs>
            
            {bookingsTab === 0 && (profile.bookings.filter(booking => !hasEpochTimePassed(booking.epoch) && !booking.canceled).length > 0 ? sortDescendingByEpoch(profile.bookings).filter(booking => !hasEpochTimePassed(booking.epoch) && !booking.canceled).map(booking => 
              <div>
                <Grid xs={12}>
                  <Grid container>
                    <Card style={{ width: "100%" }}>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-between", padding: "0" }}>
                        <Tooltip title='Click to view booking details.'>
                          <CardActionArea onClick={() => handleBookingDetailsDialogOpen(getBookingIndex(booking))}>
                                <Grid xs={12} lg={6} style={{padding: "16px"}}>
                                  <Typography variant='h6'>
                                    {stringifyBooking(booking)}
                                  </Typography>
                                </Grid>
                          </CardActionArea>
                        </Tooltip>
                        <Grid xs={12} lg={6} style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                          {/* <CheckCircleIcon style={{ color: "green" }} onClick={() => handleBookingCompletedDialogOpen(getBookingIndex(booking))} /> */}
                          <Button variant="outlined" disabled={booking.onTheWay} onClick={() => handleOnTheWayDialogOpen(getBookingIndex(booking))}>
                            On My Way
                          </Button>
                          <Button variant="outlined" onClick={() => handleCancelBookingConfirmationDialogOpen(getBookingIndex(booking))}>
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : <Grid xs={12}>You have no upcoming bookings.</Grid>)}
            {bookingsTab === 1 && (profile.bookings.filter(booking => hasEpochTimePassed(booking.epoch) && !booking.completed && !booking.canceled).length > 0 ? sortDescendingByEpoch(profile.bookings).filter(booking => hasEpochTimePassed(booking.epoch) && !booking.completed).map(booking =>
              <div>
                <Grid xs={12}>
                  <Grid container>
                    <Card style={{ width: "100%" }}>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-between", padding: "0" }}>
                        <Tooltip title='Click to view booking details.'>
                          <CardActionArea onClick={() => handleBookingDetailsDialogOpen(getBookingIndex(booking))}>
                            <Grid xs={12} lg={6} style={{padding: "16px"}}>
                              <Typography variant='h6'>
                                {stringifyBooking(booking)}
                              </Typography>
                            </Grid>
                          </CardActionArea>
                        </Tooltip>
                        <Grid xs={12} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Button variant="outlined" onClick={() => handleBookingCompletedDialogOpen(getBookingIndex(booking))}>
                            Mark as Completed
                          </Button>
                          {/* <CancelIcon style={{ color: "red" }} onClick={() => handleCancelBookingConfirmationDialogOpen(getBookingIndex(booking))} /> */}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : <Grid xs={12}>You have no bookings in progress.</Grid>)}
            {/* {JSON.stringify(profile.bookings)} */}
            {bookingsTab === 2 && (profile.bookings.filter(booking => booking.completed).length > 0 ? sortDescendingByEpoch(profile.bookings).filter(booking => booking.completed).map(booking =>
              <div>
                <Grid xs={12}>
                  <Grid container>
                    <Card style={{ width: "100%" }}>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-around", padding: "0" }}>
                        <Grid xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {booking.canceled && <CancelIcon style={{ color: "red" }} />}
                          {booking.completed && <CheckCircleIcon style={{ color: "green" }} />}
                        </Grid>
                        <Tooltip title='Click to view booking details.'>
                          <CardActionArea onClick={() => handleBookingDetailsDialogOpen(getBookingIndex(booking))}>
                            <Grid xs={8} style={{padding: "16px"}}>
                              <Typography variant='h6'>
                                {stringifyBooking(booking)}
                              </Typography>
                            </Grid>
                          </CardActionArea>
                        </Tooltip>
                        <Grid xs={3} sx={{display: "inline-flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography variant='h6'>
                            ${booking.customerCanceledWithin24HourPeriod || booking.completed ? `${booking.payout}` : '0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : <Grid xs={12}>You have no completed bookings.</Grid>)}
            {bookingsTab === 3 && (profile.bookings.filter(booking => booking.canceled).length > 0 ? sortDescendingByEpoch(profile.bookings).filter(booking => booking.canceled).map(booking =>
              <div>
                <Grid xs={12}>
                  <Grid container>
                    <Card style={{ width: "100%" }}>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-around", padding: "0" }}>
                        <Grid xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {booking.canceled && <CancelIcon style={{ color: "red" }} />}
                          {booking.completed && <CheckCircleIcon style={{ color: "green" }} />}
                        </Grid>
                        <Tooltip title='Click to view booking details.'>
                          <CardActionArea onClick={() => handleBookingDetailsDialogOpen(getBookingIndex(booking))}>
                            <Grid xs={8} style={{padding: "16px"}}>
                              <Typography variant='h6'>
                                {stringifyBooking(booking)}
                              </Typography>
                            </Grid>
                          </CardActionArea>
                        </Tooltip>
                        <Grid xs={3} sx={{display: "inline-flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography variant='h6'>
                            ${booking.customerCanceledWithin24HourPeriod || booking.completed ? `${booking.payout}` : '0.00'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : <Grid xs={12}>You have no cancelled bookings.</Grid>)}
            
            
            <Dialog open={bookingDetailsDialogOpen} onClose={handleBookingDetailsDialogClose} fullWidth maxWidth="md">
              <DialogTitle>Booking Details</DialogTitle>
              <DialogContent>
                <Typography variant="h6">
                  Time: <span style={{ fontWeight: "400" }}>{convertUnixTimestampToCustomFormat(selectedBooking.epoch)}</span>
                  <br />
                  Massage Type: <span style={{ fontWeight: "400" }}>{selectedBooking.type !== undefined ? formatMassageType(selectedBooking.type) : ""}</span>
                  <br />
                  Duration: <span style={{ fontWeight: "400" }}>{selectedBooking.duration} minutes</span>
                  <br />
                  Customer Name: <span style={{ fontWeight: "400" }}>{selectedBooking.customerName}</span>
                  <br />
                  Location: <span style={{ fontWeight: "400" }}>{selectedBooking.location}</span>
                  <br />
                  <Button onClick={() => { window.open("https://www.google.com/maps/place/" + selectedBooking.location) }} startIcon={<Map />}>View Location On Map</Button>
                  <br />
                  Customer Notes: <code>{selectedBooking.notes}</code>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleBookingDetailsDialogClose}>Close</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={cancelBookingDialogOpen} onClose={handleCancelBookingConfirmationDialogClose}>
              <DialogTitle>
                Are you sure you want to cancel this booking?
              </DialogTitle>
              <DialogContent>
                <Typography variant='body1'>
                  {stringifyBooking(selectedBooking)}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelBookingConfirmationDialogClose}>Back</Button>
                <Button onClick={handleCancelSelectedBooking} disabled={disableDialogActionButton}>Yes, cancel booking</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={bookingCompletedDialogOpen} onClose={handleBookingCompletedDialogClose}>
              <DialogTitle>
                Booking completed
              </DialogTitle>
              <DialogContent>
                <Typography variant='body1'>
                  {stringifyBooking(selectedBooking)}.
                  <br />
                  <br />
                  You'll be sent an Interac e-transfer for ${selectedBooking.payout} within 10 business days of confirming.
                  <br />
                  <br />
                  Enter the payment code you got from the customer below (case sensitive):
                </Typography>
                <TextField label="Payment code" variant='outlined' value={paymentCode} onChange={handlePaymentCodeChange} style={{marginTop: "15px"}} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleBookingCompletedDialogClose}>Back</Button>
                <Button onClick={handleCompletedBooking} disabled={disableDialogActionButton}>Confirm</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={onTheWayDialogOpen} onClose={handleOnTheWayDialogClose}>
              <DialogTitle>
                On the way
              </DialogTitle>
              <DialogContent>
                <Typography variant='body1'>
                  {stringifyBooking(selectedBooking)}.
                  <br />
                  <br />
                  Let them know you're on your way.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleOnTheWayDialogClose}>Back</Button>
                <Button onClick={handleOnTheWay} disabled={disableDialogActionButton}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </div>}
          {tab === 1 && <div>
            <Grid xs={12}>
              <Typography variant='h3'>
                Services
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Stack spacing={1}>
              {profile.offers.map((offer, index) => {
                return (
                  <Grid xs={12}>
                    <Paper>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h6'>
                          {stringifyOffer(offer)}
                        </Typography>
                        <RemoveCircleIcon style={{ color: "red" }} onClick={() => {
                          //debugger;
                          showSnackbar("Service removed");
                          let profileMut = JSON.parse(JSON.stringify(profile));
                          profileMut.offers = profile.offers.filter(i => i !== offer);
                          profileMut.deals = profileMut.deals.filter(deal => deal.offer !== index);
                          for(let deal of profileMut.deals)
                          {
                            let linkedOffer = profile.offers[deal.offer];
                            let ind = profileMut.offers.findIndex(off => deepEqual(linkedOffer, off));
                            deal.offer = ind;
                          }
                          setProfile(profileMut);
                        }} />
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
              {/* {JSON.stringify(profile.deals)} */}
            </Stack>
            <Grid xs={12}>
              <Button onClick={handleAddOfferDialogOpen}>Add Service</Button>

            </Grid>
            <Dialog open={addOfferDialogOpen} onClose={handleAddOfferDialogClose} fullWidth maxWidth="md">
              <DialogTitle>Add service</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid>
                    <FormControl>
                      <FormLabel>Massage Type</FormLabel>
                      <RadioGroup value={newOffer.massageType} onChange={handleAddOfferMassageTypeChange}>
                        <FormControlLabel value="swedish" control={<Radio />} label="Swedish (Relaxation)" />
                        <FormControlLabel value="deep-tissue" control={<Radio />} label="Deep Tissue" />
                        <FormControlLabel value="prenatal" control={<Radio />} label="Prenatal" />
                        <FormControlLabel value="hot-stone" control={<Radio />} label="Hot Stone" />
                        <FormControlLabel value="aromatherapy" control={<Radio />} label="Aromatherapy" />
                        <FormControlLabel value="trigger-point" control={<Radio />} label="Trigger Point" />
                        <FormControlLabel value="reflexology" control={<Radio />} label="Reflexology" />
                        <FormControlLabel value="thai" control={<Radio />} label="Thai" />
                        <FormControlLabel value="myofascial" control={<Radio />} label="Myofascial" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Duration</InputLabel>
                      <Select value={newOffer.duration} onChange={handleAddOfferMassageDurationChange} label="Duration">
                        <MenuItem value="30">30 minutes</MenuItem>
                        <MenuItem value="45">45 minutes</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">1.5 hours</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <TextField InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">CAD</InputAdornment>
                    }} inputProps={{ step: 0.01 }} label="Rate" variant="outlined" helperText="Note: this amount includes our 20% fee" fullWidth value={newOffer.cost} onChange={handleAddOfferRateChange} type="number" onBlur={() => setNewOffer({...newOffer, cost: parseFloat(newOffer.cost).toFixed(2)})} />
                  </Grid>
                  <Grid xs={12}>
                    <TextField placeholder={`The best ${newOffer.massageType} massage you'll ever feel!`} label="Description" value={newOffer.description} onChange={handleAddOfferDescriptionChange} fullWidth multiline />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddOfferDialogClose}>Cancel</Button>
                <Button onClick={handleAddNewOffer}>Add</Button>
              </DialogActions>
            </Dialog>
          </div>}
          {tab === 4 && <div>
            <Grid xs={12}>
              <Typography variant='h3'>
                Deals
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Stack spacing={1}>
              {profile.offers.length > 0 && profile.deals.map(deal => {
                return (
                  <Grid xs={12}>
                    <Paper>
                      <Grid xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h6'>
                          {stringifyDeal(deal)}
                        </Typography>
                        <RemoveCircleIcon style={{ color: "red" }} onClick={() => showSnackbar("Deal removed") | setProfileVariable("deals", profile.deals.filter(i => !deepEqual(i, deal)))} />
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
            </Stack>
            {profile.offers.length > 0 ?
            <Grid xs={12}>
              <Button onClick={handleAddDealDialogOpen}>Add Deal</Button>
            </Grid> : <Grid xs={12}>To create a deal, first add a service.</Grid>}
            <Dialog open={addDealDialogOpen} onClose={handleAddDealDialogClose} fullWidth maxWidth="md">
              <DialogTitle>Add deal</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                      <InputLabel>Offer</InputLabel>
                      <Select value={newDeal.offer} onChange={(e) => handleAddDealChange(e, "offer")} label="Offer">
                        {profile.offers.map((offer, index) => <MenuItem value={index}>{stringifyOffer(offer)}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <TextField type="number" label="Discount %" variant="outlined" fullWidth value={newDeal.discount} onChange={(e) => handleAddDealChange(e, "discount")} />
                  </Grid>
                  <Grid xs={12}>
                    <TextField type="text" label="Description" variant="outlined" fullWidth value={newDeal.description} onChange={(e) => handleAddDealChange(e, "description")} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddDealDialogClose}>Cancel</Button>
                <Button onClick={handleAddNewDeal}>Add</Button>
              </DialogActions>
            </Dialog>
          </div>}
          {tab !== 3 &&
            <Grid xs={12}>
              <Button onClick={saveProfile} variant='contained'>Save</Button>
              {tab == 0 && <Button onClick={handleSignOut} variant='outlined' sx={{marginLeft: "10px"}} color='error'>Sign Out</Button>}
            </Grid>}
          <Snackbar open={snackbarOpen} onClose={hideSnackbar} TransitionComponent={Slide} message={snackbarMessage} autoHideDuration={3500} anchorOrigin={{horizontal: "center", vertical: "bottom"}} />
          <Dialog open={discardChangesDialogOpen} onClose={handleCancelDiscardChanges}>
            <DialogTitle>
              Unsaved changes
            </DialogTitle>
            <DialogContent>
              <Typography variant='body1'>
                Would you like to save your changes before moving on?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDiscardChanges}>Back</Button>
              <Button variant='outlined' onClick={handleDiscardChanges}>Discard</Button>
              <Button variant='contained' onClick={handleSaveFromDiscardChangesDialog}>Save</Button>
            </DialogActions>
          </Dialog>
          <Dialog onClose={null} open={welcomeDialogOpen}>
            <DialogTitle>
              Welcome to Bamboo!
            </DialogTitle>
            <DialogContent>
              <Typography variant='body1'>
                Here's how to get started:
                <br />
                <br />
                <b>1:📝 Profile Set-Up:</b> Fill out the required sections to showcase your skills.
                <br />
                <br />
                <b>2:🕒 Await Bookings:</b> Your profile goes live on our site, attracting clients to book your services. You'll get an email when somebody books you, and it will show up in your 'Bookings' tab.
                <br />
                <br />
                <b>3:💸 Get Paid:</b> Perform massages and get paid!
                <br />
                <br />
                Simple as that! Excited to have you with us. Let's make massages happen. 🌟
                <br />
                <br />
                By proceeding, you agree to our <a href='/terms' target='_blank'>term & conditions</a>.
                <br />
                <br />
                Note: To view this message again, click on the <InfoIcon sx={{marginBottom: "2.7px"}} /> button in the 'Profile' tab.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleWelcomeDialogClose}>Proceed</Button>
            </DialogActions>
          </Dialog>
          <Popover
            
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
          >
            <Typography sx={{ p: 1 }}>{popoverText}</Typography>
          </Popover>
        </div>
      </Grid>
      <Backdrop
        open={loadingBackropOpen}
      >
        <CircularProgress style={{color: "white"}} />
      </Backdrop>
    </div>
  );
}
