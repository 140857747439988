const TERMS_TEXT = `Bamboo - Massage Therapists Terms & Agreement

Welcome to Bamboo, a leading in -home massage service platform designed to connect independent massage therapists with clients seeking personalised, professional massage therapy in the comfort of their homes.By joining our platform, you become part of a community dedicated to providing exceptional service and convenience.This agreement outlines the terms and conditions of your engagement with Bamboo and the services we provide.
1. Introduction
By registering as a massage therapist(hereinafter referred to as "Service Provider") on Bamboo's platform, you agree to adhere to the terms and conditions set forth in this agreement. Your acceptance of these terms is a prerequisite for using our services to connect with clients.
2. Digital Asset Rights
As a Service Provider, you grant Bamboo the unrestricted right to display, distribute, and use any digital assets, including images and text, that you upload to our website.These assets are crucial for marketing your services to potential clients and enhancing your profile's attractiveness.
3. Independence of Service Providers
You acknowledge that you are an independent contractor, not an employee of Bamboo.As such, you are fully responsible for the quality and legality of the services you provide, including obtaining necessary insurance and maintaining the tools required for your services.You agree to indemnify and hold Bamboo harmless in any disputes arising from your services.
4. Responsibilities of Service Providers
While Bamboo facilitates connections between Service Providers and clients, it does not partake in the actual provision of massage therapy services.You are responsible for:
Ensuring the professional quality of your services.
Holding valid insurance.
Providing all necessary tools and supplies for your services.
Adhering to all applicable laws and regulations governing massage therapy.
5. Client Engagement and Solicitation
You agree not to solicit clients obtained through Bamboo for direct engagement outside the platform.Instead, you are encouraged to direct clients to your dedicated booking link on Bamboo's website for all future bookings, thereby maintaining the integrity of the Bamboo community.
6. Services Provided by Bamboo
Bamboo is committed to supporting your success on our platform by providing:
Comprehensive advertising to attract clients.
Efficient payment processing.
Streamlined booking services.
Dedicated customer communication management.
7. Service Fee
In exchange for the services Bamboo provides, a fee of 20 % will be deducted from the price you set for your services.This fee supports the continuous operation and improvement of Bamboo's platform, ensuring a steady stream of clients and streamlined service delivery.
8. Payment Terms
Bamboo will process payments from clients and remit your earnings to you within 10 business days after receiving payment from clients.This ensures a reliable and timely payment process for the services you provide.
9. Agreement to Terms
By using Bamboo's platform, you acknowledge that you have read, understood, and agreed to these terms and conditions. This agreement is effective upon your acceptance and will govern your use of the Bamboo platform and services.
10. Amendments
Bamboo reserves the right to modify these terms and conditions at any time.You will be notified of any changes, which will become effective upon posting on the Bamboo website.
Thank you for choosing to be part of the Bamboo community.We look forward to supporting your journey as a distinguished provider of in -home massage services, delivering wellness and relaxation to clients across our platform.
By registering as a Service Provider on Bamboo, you signify your acceptance of these Terms and Agreement.

`;

export { TERMS_TEXT }

function Terms() {
    return (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}><div style={{ width: "700px", marginTop: "50px" }}><strong>
        Bamboo - Massage Therapists Terms & Agreement
    </strong>
        <p style={{ marginTop: "revert" }}>
            Welcome to Bamboo, a leading in-home massage service platform designed to connect independent massage therapists with clients seeking personalised, professional massage therapy in the comfort of their homes. By joining our platform, you become part of a community dedicated to providing exceptional service and convenience. This agreement outlines the terms and conditions of your engagement with Bamboo and the services we provide.
        </p>
        <p>
            <strong>1. Introduction</strong>
        </p>
        <p>
            By registering as a massage therapist (hereinafter referred to as "Service Provider") on Bamboo's platform, you agree to adhere to the terms and conditions set forth in this agreement. Your acceptance of these terms is a prerequisite for using our services to connect with clients.
        </p>
        <p>
            <strong>2. Digital Asset Rights</strong>
        </p>
        <p>
            As a Service Provider, you grant Bamboo the unrestricted right to display, distribute, and use any digital assets, including images and text, that you upload to our website. These assets are crucial for marketing your services to potential clients and enhancing your profile's attractiveness.
        </p>
        <p>
            <strong>3. Independence of Service Providers</strong>
        </p>
        <p>
            You acknowledge that you are an independent contractor, not an employee of Bamboo. As such, you are fully responsible for the quality and legality of the services you provide, including obtaining necessary insurance and maintaining the tools required for your services. You agree to indemnify and hold Bamboo harmless in any disputes arising from your services.
        </p>
        <p>
            4. <strong>Responsibilities of Service Providers</strong>
        </p>
        <p>
            While Bamboo facilitates connections between Service Providers and clients, it does not partake in the actual provision of massage therapy services. You are responsible for:
        </p>
        <ul>

            <li>Ensuring the professional quality of your services.</li>

            <li>Holding valid insurance.</li>

            <li>Providing all necessary tools and supplies for your services.</li>

            <li>Adhering to all applicable laws and regulations governing massage therapy.
            </li>
        </ul>
        <p>
            <strong>5. Client Engagement and Solicitation</strong>
        </p>
        <p>
            You agree not to solicit clients obtained through Bamboo for direct engagement outside the platform. Instead, you are encouraged to direct clients to your dedicated booking link on Bamboo's website for all future bookings, thereby maintaining the integrity of the Bamboo community.
        </p>
        <p>
            <strong>6. Services Provided by Bamboo</strong>
        </p>
        <p>
            Bamboo is committed to supporting your success on our platform by providing:
        </p>
        <ul>

            <li>Comprehensive advertising to attract clients.</li>

            <li>Efficient payment processing.</li>

            <li>Streamlined booking services.</li>

            <li>Dedicated customer communication management.
            </li>
        </ul>
        <p>
            <strong>7. Service Fee</strong>
        </p>
        <p>
            In exchange for the services Bamboo provides, a fee of 20% will be deducted from the price you set for your services. This fee supports the continuous operation and improvement of Bamboo's platform, ensuring a steady stream of clients and streamlined service delivery.
        </p>
        <p>
            <strong>8. Payment Terms</strong>
        </p>
        <p>
            Bamboo will process payments from clients and remit your earnings to you within 10 business days after receiving payment from clients. This ensures a reliable and timely payment process for the services you provide.
        </p>
        <p>
            <strong>9. Agreement to Terms</strong>
        </p>
        <p>
            By using Bamboo's platform, you acknowledge that you have read, understood, and agreed to these terms and conditions. This agreement is effective upon your acceptance and will govern your use of the Bamboo platform and services.
        </p>
        <p>
            <strong>10. Amendments</strong>
        </p>
        <p>
            Bamboo reserves the right to modify these terms and conditions at any time. You will be notified of any changes, which will become effective upon posting on the Bamboo website.
        </p>
        <p>
            Thank you for choosing to be part of the Bamboo community. We look forward to supporting your journey as a distinguished provider of in-home massage services, delivering wellness and relaxation to clients across our platform.
        </p>
        <p>
            <em>By registering as a Service Provider on Bamboo, you signify your acceptance of these Terms and Agreement.</em>
        </p></div></div>)
}

export default Terms
