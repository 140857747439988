
export const Welcome = () => {

    return (
        <div>
            <h1>Welcome to your RMT Portal!</h1>
            <h3>Please login to manage your profile on Bamboo</h3>
        </div>
    );
};

